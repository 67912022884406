import React from 'react';
import Icon from '../../Icon';
import SmoothLinkScroll from 'react-anchor-link-smooth-scroll';
import * as styles from './LogoBrenno.module.css';

const LogoBrenno = () => {
   return (
      <div>
         <SmoothLinkScroll
            href="#hero"
            offset="200"
            className={styles.logoBrenno}
         >
            <Icon
               name="logo"
               size="54"
               aria-label="Logomark Brenno Pellegrini"
            ></Icon>
            <span className={styles.logoName}>Brenno Pellegrini</span>
         </SmoothLinkScroll>
      </div>
   );
};

export default LogoBrenno;
