import React from 'react';
import Icon from '../../Icon';
import { Link } from 'gatsby';
import * as styles from './HeaderWork.module.css';

const HeaderWork = ({ location }) => {
   const startingPathName = location.pathname.slice(1, -1);
   const normalizePathName = startingPathName.replace('-', ' ');
   const pathName =
      normalizePathName.charAt(0).toUpperCase() + normalizePathName.slice(1);

   return (
      <header className={styles.headerContainern}>
         <Link to="/" className={styles.backButton}>
            <Icon name="chevron-left" /> Back
         </Link>
         <p className={styles.projectName}>Project: {pathName}</p>
      </header>
   );
};

export default HeaderWork;
