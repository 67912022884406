import React, { useState, useRef } from 'react';
import LogoBrenno from '../Logo/LogoBrenno';
import SmoothLinkScroll from 'react-anchor-link-smooth-scroll';
import * as styles from './Header.module.css';

const Header = () => {
   const [isHamburgerChecked, setHamburgerChecked] = useState(false);

   const mobileMenuOverlay = useRef();
   const mobileMenu = useRef();

   const openCloseMobileMenu = () => {
      mobileMenu.current.classList.toggle(styles.mobileMenuContainer);
      mobileMenuOverlay.current.classList.toggle(styles.elemVisible);

      setHamburgerChecked((current) => !current);
   };

   return (
      <header className={styles.header}>
         <div className={styles.headerContainer}>
            <LogoBrenno />

            <nav className={styles.desktopNavigation}>
               <SmoothLinkScroll href="#about" offset="200">
                  About
               </SmoothLinkScroll>
               <SmoothLinkScroll href="#work" offset="200">
                  Projects
               </SmoothLinkScroll>
               <SmoothLinkScroll href="#contact" offset="200">
                  Contact
               </SmoothLinkScroll>
            </nav>

            <div id="mobile-navigation" className={styles.mobileNavigation}>
               <input
                  type="checkbox"
                  className={styles.menuCheckbox}
                  aria-label="Hamburger menu button"
                  checked={isHamburgerChecked}
                  value={isHamburgerChecked}
                  onChange={openCloseMobileMenu}
               ></input>
               <div>
                  <div
                     className={`${styles.hamburgerLine} ${styles.line1}`}
                  ></div>
                  <div
                     className={`${styles.hamburgerLine} ${styles.line2}`}
                  ></div>
               </div>
            </div>
         </div>

         <div
            className={`${styles.menuOveraly} ${styles.elemTransition}`}
            ref={mobileMenuOverlay}
         >
            <div ref={mobileMenu} className={styles.mobileMenuContainer}>
               <nav className={styles.mobileMenu}>
                  <SmoothLinkScroll
                     href="#about"
                     offset="200"
                     onClick={openCloseMobileMenu}
                     className={`${styles.mobileMenuItem}`}
                  >
                     About
                  </SmoothLinkScroll>
                  <SmoothLinkScroll
                     href="#work"
                     offset="200"
                     onClick={openCloseMobileMenu}
                     className={`${styles.mobileMenuItem} ${styles.menuItem2}`}
                  >
                     Projects
                  </SmoothLinkScroll>
                  <SmoothLinkScroll
                     href="#contact"
                     offset="200"
                     onClick={openCloseMobileMenu}
                     className={`${styles.mobileMenuItem} ${styles.menuItem3}`}
                  >
                     Contact
                  </SmoothLinkScroll>
               </nav>
            </div>
         </div>
      </header>
   );
};

export default Header;
