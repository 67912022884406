// extracted by mini-css-extract-plugin
export var desktopNavigation = "Header-module--desktop-navigation--8fe0a";
export var elemTransition = "Header-module--elem-transition--e08cc";
export var elemVisible = "Header-module--elem-visible--295c2";
export var hamburgerLine = "Header-module--hamburger-line--740f0";
export var header = "Header-module--header--07fe4";
export var headerContainer = "Header-module--header-container--3462b";
export var line1 = "Header-module--line1--63fe7";
export var line2 = "Header-module--line2--ee191";
export var logoName = "Header-module--logo-name--9d863";
export var menuCheckbox = "Header-module--menu-checkbox--17109";
export var menuItem2 = "Header-module--menu-item2--5f92b";
export var menuItem3 = "Header-module--menu-item3--e86ac";
export var menuItems = "Header-module--menuItems--91916";
export var menuOveraly = "Header-module--menu-overaly--13b0d";
export var mobileMenu = "Header-module--mobile-menu--9f548";
export var mobileMenuContainer = "Header-module--mobile-menu-container--ea857";
export var mobileMenuItem = "Header-module--mobile-menu-item--9b174";
export var mobileNavigation = "Header-module--mobile-navigation--7e3b4";