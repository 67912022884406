import React from 'react'
import { useStaticQuery, graphql } from "gatsby"

const Icon = ({ name, color, size }) => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "sprite.svg" }) {
        publicURL
      }
    }
  `)

  return (
    <svg
      aria-hidden="true"
      width={size || '14'}
      viewBox="0 0 20 20"
      fill={color || 'var(--color-forground)'}>
      <use xlinkHref={`${data.file.publicURL}#${name}`} />
    </svg>
  )
}

export default Icon
