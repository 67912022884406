import * as React from 'react';

import Header from './Header/Header/Header';
import HeaderWork from './Header/HeaderWork/HeaderWork';
import ThemeAudioBar from './Footer/ThemeAudioBar';
import Cursor from './Cursor/Cursor';

const Layout = ({ children, location }) => {
   const isWork = location && location.pathname !== '/';

   return (
      <>
         <Cursor location={location} />

         {isWork ? <HeaderWork location={location} /> : <Header />}
         <ThemeAudioBar />

         <main className="z-2">{children}</main>
      </>
   );
};

export default Layout;
