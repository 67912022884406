// extracted by mini-css-extract-plugin
export var audioBar = "AudioWave-module--audio-bar--15138";
export var audioBar1 = "AudioWave-module--audio-bar1--63c6a";
export var audioBar2 = "AudioWave-module--audio-bar2--b57c8";
export var audioBar3 = "AudioWave-module--audio-bar3--eb481";
export var audioBar4 = "AudioWave-module--audio-bar4--af5f6";
export var audioBar5 = "AudioWave-module--audio-bar5--8dcbe";
export var audioButton = "AudioWave-module--audio-button--fd442";
export var audioButtonContainer = "AudioWave-module--audio-button-container--833c8";
export var danceHeight1 = "AudioWave-module--danceHeight1--1d4ad";
export var danceHeight2 = "AudioWave-module--danceHeight2--bb4ba";
export var danceHeight3 = "AudioWave-module--danceHeight3--50d81";
export var danceHeight4 = "AudioWave-module--danceHeight4--91412";
export var danceHeight5 = "AudioWave-module--danceHeight5--c3728";
export var sphere = "AudioWave-module--sphere--6e960";
export var stopBarAnimation = "AudioWave-module--stopBarAnimation--9ae3e";
export var stopBarMoovement = "AudioWave-module--stop-bar-moovement--4f0b0";