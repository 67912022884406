import React, { useEffect, useRef } from 'react';
import * as styles from './Cursor.module.css';

const Cursor = ({ location }) => {
   const cursorContainer = useRef();
   const mainCursor = useRef();
   const secondaryCursor = useRef();
   const mainCursorDot = useRef();
   const buttonsAndLinksRef = useRef([]);
   const iframesRef = useRef([]);

   useEffect(() => {
      if (
         /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
         )
      ) {
         cursorContainer.current.remove();
      } else {
         cursorContainer.current.style.visibility = 'visible';
         mooveCursor();
      }
   }, []);

   const mooveCursor = () => {
      document.addEventListener('mousemove', (event) => {
         const { clientX, clientY } = event;

         mainCursor.current.style.transform = `translate(
         ${clientX - mainCursor.current.clientWidth / 2}px,
         ${clientY - mainCursor.current.clientHeight / 2}px)`;

         setTimeout(() => {
            secondaryCursor.current.style.transform = `translate(
          ${clientX - secondaryCursor.current.clientWidth / 2}px,
          ${clientY - secondaryCursor.current.clientHeight / 2}px`;
         }, 180);
      });
   };

   useEffect(() => {
      hoverInteractiveElements();
      hoverIframes();
   }, [location]);

   const hoverInteractiveElements = () => {
      buttonsAndLinksRef.current = Array.from(
         document.querySelectorAll('a, button, input[type=checkbox]')
      );
      mainCursorDot.current.classList.remove(styles.hoverCursor);

      buttonsAndLinksRef.current.forEach((elem) => {
         elem.addEventListener('mouseenter', () => {
            mainCursorDot.current.classList.add(styles.hoverCursor);
         });
         elem.addEventListener('mouseleave', () => {
            mainCursorDot.current.classList.remove(styles.hoverCursor);
         });
      });
   };

   const hoverIframes = () => {
      iframesRef.current = Array.from(document.querySelectorAll('iframe'));
      iframesRef.current.forEach((elem) => {
         elem.addEventListener('mouseenter', () => {
            cursorContainer.current.style.visibility = 'hidden';
         });
         elem.addEventListener('mouseleave', () => {
            cursorContainer.current.style.visibility = 'visible';
         });
      });
   };

   return (
      <div ref={cursorContainer} className={styles.cursorContainer}>
         <div ref={mainCursor} className={styles.mainCursor}>
            <div ref={mainCursorDot} className={styles.dotCursor}></div>
         </div>
         <div ref={secondaryCursor} className={styles.secondaryCursor}></div>
      </div>
   );
};

export default Cursor;
