import React from 'react';
import ThemeSwitcher from './ThemeSwitcher';
import AudioWave from './AudioWave';

const ThemeAudioBar = () => {
   return (
      <>
         <ThemeSwitcher />
         <AudioWave />
      </>
   );
};

export default ThemeAudioBar;
