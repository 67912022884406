import React from 'react';
import * as styles from './AudioWave.module.css';

import soundtrack from '../../audio/soundtrack.mp3';
import { useAudio } from '../../hooks';

const AudioWave = () => {
   const { paused, play, pause } = useAudio(soundtrack, {
      autoplay: true,
      loop: true,
   });

   const toggle = paused ? play : pause;

   return (
      <div className={styles.audioButtonContainer}>
         <button
            className={styles.audioButton}
            onClick={() => toggle()}
            aria-label="Button to turn on and off the background audio"
         >
            <div
               className={
                  paused
                     ? `${styles.stopBarMoovement} ${styles.audioBar}`
                     : `${styles.audioBar1} ${styles.audioBar}`
               }
            ></div>
            <div
               className={
                  paused
                     ? `${styles.stopBarMoovement} ${styles.audioBar}`
                     : `${styles.audioBar2} ${styles.audioBar}`
               }
            ></div>
            <div
               className={
                  paused
                     ? `${styles.stopBarMoovement} ${styles.audioBar}`
                     : `${styles.audioBar3} ${styles.audioBar}`
               }
            ></div>
            <div
               className={
                  paused
                     ? `${styles.stopBarMoovement} ${styles.audioBar}`
                     : `${styles.audioBar4} ${styles.audioBar}`
               }
            ></div>
            <div
               className={
                  paused
                     ? `${styles.stopBarMoovement} ${styles.audioBar}`
                     : `${styles.audioBar5} ${styles.audioBar}`
               }
            ></div>
         </button>
      </div>
   );
};

export default AudioWave;
