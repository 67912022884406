import React, { useState, useEffect } from 'react';
import * as styles from './ThemeSwitcher.module.css';

import { Theme, useTheme } from '../../contexts';

const ThemeSwitcher = () => {
   const [theme, setTheme] = useTheme();
   const targetTheme = theme === Theme.Light ? Theme.Dark : Theme.Light;

   const [animating, setAnimating] = useState(false);
   const animatingClass = animating ? styles.themeSwitchertAnimation : '';

   const toggleTheme = () => {
      setTheme(targetTheme);
      setAnimating(true);
   };

   useEffect(() => {
      const animate = setTimeout(() => setAnimating(false), 1100);

      return () => {
         clearTimeout(animate);
      };
   }, [animating]);

   return (
      <div className={styles.themeSwitcherButton}>
         <div>
            <div
               className={`${styles.themeSwitcherAnimator} ${animatingClass}`}
            ></div>

            <button
               aria-label={`Switch to ${targetTheme} theme`}
               className={styles.themeSwitchert}
               onClick={toggleTheme}
            ></button>
         </div>
      </div>
   );
};

export default ThemeSwitcher;
